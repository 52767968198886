import {
  Box,
  Button,
  Flex,
  Stack,
  Image,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { DataTable } from 'components/DataTable'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createColumnHelper } from '@tanstack/react-table'
import { DealTransaction } from 'types'
import i18n from 'i18n'
import { useGetDealsTransactionsMutation } from 'store/services/user.api'
import { currencyFormat } from 'utils/currencyFormat'
import moment from 'moment'
import { TransactionRenderer } from './TransactionRenderer'
import { TransactionDetailsModal } from './TransactionDetails'
import { useSelector } from 'react-redux'
import { dealTransactionsSelector } from 'store/selectors/user'
import { MobileDealTransaction } from './MobileDealTransaction'
import noDataImage from 'assets/images/no-records.svg'
import { ExchangeRateRenderer } from 'pages/HomePage/DealsTable/ExchangeRateRenderer'
import { ScrollContext } from 'pages/MainLayout/ScrollContext'
import { AmplitudeEvents, useTrackAction } from 'pages/MainLayout/AmplitudeProvider'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { getDealTransactionSettings } from 'hooks/useDealTransactionSettings'

const columnHelper = createColumnHelper<DealTransaction>()
const createColumnDefs = (onDetailsClick: (deal: DealTransaction) => void) => [
  columnHelper.accessor(
    (props) => {
      if (!props) return null
      return {
        remarkType: props.remark,
      }
    },
    {
      header: i18n.t('tables.transactions.headers.transaction') as string,
      enableSorting: false,
      cell: (props) => {
        const value = props.getValue()
        return value ? <TransactionRenderer transactionType={value.remarkType} /> : <></>
      },
    },
  ),
  columnHelper.accessor(
    (props) => {
      if (!props) return null
      return props
    },
    {
      header: i18n.t('tables.transactions.headers.amount') as string,
      enableSorting: true,
      cell: (props) => {
        const value = props.getValue()
        console.log(value)
        const dealTransactionSettings = getDealTransactionSettings(value)
        return value ? (
          <div>
            {value.commType ? (
              <>
                {currencyFormat(+value.amount)}{' '}
                {value.commType === 'bought'
                  ? value.deal[dealTransactionSettings.slugKey as 'fromCurrency' | 'toCurrency']
                      .slug
                  : value.commType === 'created'
                  ? value.deal['fromCurrency' as 'fromCurrency' | 'toCurrency'].slug
                  : value.commType === 'sold'
                  ? value.deal['fromCurrency' as 'fromCurrency' | 'toCurrency'].slug
                  : ''}
              </>
            ) : (
              <>
                {currencyFormat(+value.amount)}{' '}
                {value.deal[dealTransactionSettings.slugKey as 'fromCurrency' | 'toCurrency'].slug}
              </>
            )}
          </div>
        ) : (
          <></>
        )
      },
    },
  ),
  columnHelper.accessor(
    (props) => {
      if (!props) return null
      return {
        rate: props.rate,
        fromCurrency: props.deal.fromCurrency.slug,
        toCurrency: props.deal.toCurrency.slug,
      }
    },
    {
      header: i18n.t('tables.transactions.headers.exchangeRate') as string,
      enableSorting: true,
      cell: (props) => {
        const value = props.getValue()

        return value ? (
          <ExchangeRateRenderer
            toCurrency={value.toCurrency}
            fromCurrency={value.fromCurrency}
            rate={value.rate}
          ></ExchangeRateRenderer>
        ) : (
          <></>
        )
      },
    },
  ),
  columnHelper.accessor(
    (props) => {
      if (!props) return null
      return {
        date: props.createdAt,
      }
    },
    {
      header: i18n.t('tables.transactions.headers.date') as string,
      enableSorting: false,
      cell: (props) => {
        const value = props.getValue()
        return value ? <div>{moment(value.date).format('MMM DD, YYYY')}</div> : <></>
      },
    },
  ),
  columnHelper.accessor(
    (props) => {
      if (!props) return null
      return {
        reference: props.reference,
      }
    },
    {
      header: i18n.t('tables.transactions.headers.id') as string,
      enableSorting: false,
      cell: (props) => {
        const value = props.getValue()
        return value ? <div>{value.reference}</div> : <></>
      },
    },
  ),
  columnHelper.accessor((props) => props, {
    id: 'action',
    enableSorting: false,
    cell: (props) => {
      return (
        props.getValue() && (
          <Button
            variant={'link'}
            color='brand.primary.purple'
            onClick={() => onDetailsClick(props.getValue())}
          >
            Details
          </Button>
        )
      )
    },
    header: '',
  }),
]

export const DealsTransactionsTable = () => {
  const { t } = useTranslation()
  const track = useTrackAction()
  const { addHandler, removeHandler } = useContext(ScrollContext)
  const transactions = useSelector(dealTransactionsSelector)
  const screenSize = useBreakpointValue({ base: 'mobile', lg: 'desktop' }, { ssr: false })
  const { isOpen: isDetailsOpen, onClose: onDetailsClose, onOpen: onDetailsOpen } = useDisclosure()
  const pageRef = useRef<number>(1)
  const [currentDeal, setCurrentDeal] = useState<DealTransaction | null>(null)
  const [getDealsTransactions, { data: dealsTransactions, isLoading: isDealsTransactionsLoading }] =
    useGetDealsTransactionsMutation()
  const handleScroll = useInfiniteScroll<{ transactions: DealTransaction[] }>(
    pageRef,
    isDealsTransactionsLoading,
    dealsTransactions,
    () => {
      getDealsTransactions({
        page: pageRef.current,
        size: 10,
        isMobile: screenSize === 'mobile',
      })
    },
  )
  const handleDetailsClick = useCallback((data: DealTransaction) => {
    setCurrentDeal(data)
    onDetailsOpen()
  }, [])

  useEffect(() => {
    track?.(AmplitudeEvents.VIEW_DEAL_TRANSACTIONS)
  }, [])

  useEffect(() => {
    addHandler?.('dealTransactions', handleScroll)
    return () => {
      removeHandler?.('dealTransactions')
    }
  }, [handleScroll])

  useEffect(() => {
    getDealsTransactions({
      page: pageRef.current,
      size: 10,
      isMobile: screenSize === 'mobile',
    })
  }, [screenSize])

  return (
    <>
      <Flex gap={'2.28rem'} direction='column'>
        <DataTable
          boxSx={{
            boxShadow: '0 0 5.14rem rgba(15, 3, 30, 0.08)',
            borderRadius: [0, '1.71rem'],
            background: ['transparent', 'white'],
            px: {
              base: '1.42rem',
              md: 0,
            },
            mx: {
              base: '-1.42rem',
              md: 0,
            },
            overflow: {
              base: 'hidden',
              md: 'auto',
            },
          }}
          isFetching={isDealsTransactionsLoading}
          height={{
            base: 'none',
            md: 'calc(100vh - 29.64rem)',
          }}
          data={screenSize !== 'mobile' ? dealsTransactions?.transactions || [] : transactions}
          meta={dealsTransactions?.meta}
          columns={createColumnDefs(handleDetailsClick)}
          variant='transactions'
          mobileRenderer={(data: DealTransaction) => (
            <MobileDealTransaction
              dealTransaction={data}
              onClick={handleDetailsClick}
            ></MobileDealTransaction>
          )}
          onPaginationChange={(page) => {
            if (page !== pageRef.current) {
              getDealsTransactions({
                page,
                size: 10,
              })
              pageRef.current = page
            }
          }}
          noDataComponent={
            <Stack spacing={'1.14rem'}>
              <Image src={noDataImage} h='8rem'></Image>
              <Box color='brand.primary.purple' fontSize={'1.14rem'}>
                No transactions found
              </Box>
            </Stack>
          }
        ></DataTable>
      </Flex>

      <TransactionDetailsModal
        isOpen={isDetailsOpen}
        onClose={() => {
          setCurrentDeal(null)
          onDetailsClose()
        }}
        transaction={currentDeal}
      ></TransactionDetailsModal>
    </>
  )
}
